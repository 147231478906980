import React from 'react'

export type CarouselItemProps = {
    children: any
    width?: string
    isActive?: boolean
}

const CarouselItem: React.FC<CarouselItemProps> = ({
    children,
    width,
    isActive
}) => {
    return (
        <div className={`carousel-item ${isActive ? 'active' : ''}`} style={{ width: width }}>
            {children}
        </div>
    )
}

export default CarouselItem