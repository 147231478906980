import React from 'react'

import './work.scss'

type Props = {

}

const Work: React.FC<Props> = () => {

    return (
        <div className="flex-center-center flex-column">
            <h3>Munkáink</h3>
            <div className="work">
                <div>item</div>
                <div>item</div>
                <div>item</div>
                <div>item</div>
                <div>item</div>
                <div>item</div>
                <div>item</div>
                <div>item</div>
                <div>item</div>
                <div>item</div>
                <div>item</div>
                <div>item</div>
                <div>item</div>
                <div>item</div>
                <div>item</div>
                <div>item</div>
            </div>
        </div>
    )
}

export default Work