import React from 'react'
import { useLocation } from 'react-router-dom'

type Props = {

}

const Contact: React.FC<Props> = () => {
    const location = useLocation()
    console.log(location)

    return (
        <div>
            <h3>Kapcsolat</h3>
            <div>
                <p>Email:</p>
                <p>csinosleszek.hu@gmail.com</p>
            </div>
        </div >
    )
}

export default Contact