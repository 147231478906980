import React, { useEffect, useState } from 'react'
import { useSwipeable } from 'react-swipeable'

import './_carousel.scss'

type Props = {
    children: any
    autoLoop?: boolean
    delay?: number
}

const Carousel: React.FC<Props> = ({
    children,
    autoLoop,
    delay
}) => {
    const [activeIndex, setActiveIndex] = useState<number>(0)
    const [paused, setPaused] = useState<boolean>(false)

    const updateIndex = (newIndex: number) => {
        if (newIndex < 0) {
            newIndex = React.Children.count(children) - 1
        } else if (newIndex >= React.Children.count(children)) {
            newIndex = 0
        }
        setActiveIndex(newIndex)
    }

    const handlers = useSwipeable({
        onSwipedLeft: () => updateIndex(activeIndex + 1),
        onSwipedRight: () => updateIndex(activeIndex - 1)
    })

    useEffect(() => {
        if (!autoLoop) {
            return
        }

        const timeDelay = delay ? delay : 3000

        const interval = setInterval(() => {
            if (!paused) {
                updateIndex(activeIndex + 1)
            }
        }, timeDelay)

        return () => {
            if (interval) {
                clearInterval(interval)
            }
        }
    })

    return (
        <>
            <div
                {...handlers}
                className="carousel"
                onMouseEnter={() => setPaused(true)}
                onMouseLeave={() => setPaused(false)}
            >

                {React.Children.map(children, (child: any, index) => {

                    return (
                        React.cloneElement(child, { width: "100%", isActive: activeIndex === index })
                    )

                })}

                <button className="carousel-control-prev"
                    type="button"
                    onClick={() => {
                        updateIndex(activeIndex - 1)
                    }}>
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next"
                    type="button"
                    onClick={() => {
                        updateIndex(activeIndex + 1)
                    }}>
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>

                <div className="carousel-indicators">

                    {React.Children.map(children, (child, index) => {
                        return (
                            <button
                                type="button"
                                data-bs-target
                                className={`${index === activeIndex ? "active" : ""}`}
                                onClick={() => {
                                    updateIndex(index);
                                }}
                            >
                            </button>
                        );
                    })}

                </div>
            </div>

        </>
    )
}

export default Carousel