import React from 'react'

import MainRoutes from './main-routes'
import Header from './header/header.component'
import Footer from './footer/footer.component'

import './main.scss'

type Props = {

}

const Main: React.FC<Props> = () => {
    return (
        <>
            <Header />
            <div className="site-container">
                <MainRoutes />
            </div>
            <Footer />
        </>
    )
}

export default Main
