import React from 'react'

import HeroNavigation from './hero-navigation.component'
import { HeroImagePosition, HeroModel } from './hero.component'


const CenteredHero: React.FC<HeroModel> = ({
    title,
    details,
    imageUrl,
    imagePosition,
    linkPath,
    linkText,
    isExternalLink,
    linkContainerDirection
}) => {
    return (
        <div className="px-4 py-5 my-5 text-center">
            {imagePosition && imagePosition === HeroImagePosition.Top &&
                <img src={imageUrl} className="d-block mx-lg-auto img-fluid" alt="Bootstrap Themes" width="700" height="500" loading="lazy" />
            }
            <h1 className="display-5 fw-bold">{title}</h1>
            <div className="col-lg-6 mx-auto">
                <p className="lead mb-4">{details}</p>
                <HeroNavigation
                    linkPath={linkPath}
                    linkText={linkText}
                    isExternalLink={isExternalLink}
                    linkContainerDirection={linkContainerDirection}
                />
            </div>
            {imagePosition && imagePosition === HeroImagePosition.Bottom &&
                <img src={imageUrl} className="mt-5 d-block mx-lg-auto img-fluid" alt="Bootstrap Themes" width="700" height="500" loading="lazy" />
            }
        </div>
    )
}

export default CenteredHero