import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { api } from '../../../shared/constants'
import TextInput from '../../../shared/ui/form/input/input.component'
import Button from '../../../shared/ui/button/button.component'
import './login.scss'


type Props = {

}

const Login: React.FC<Props> = () => {
    const [nodeMsg, setNodeMsg] = useState('')

    useEffect(() => {
        const fetchData = async () => {
            const msg = await loadAsync(`${api}/test`)
            console.log(msg)
            setNodeMsg(msg)
        }

        fetchData()
    }, [])

    const loadAsync = async (url: string) => {
        try {
            const response = await fetch(url)
            return response.json()
        }
        catch (error: any) {
            if (error) {
                return error.message
            }
        }
    }

    return (
        <div className="login">
            <h3 className="">Bejelentkezés</h3>

            <div className="login-container">
                <TextInput
                    label="Email"
                    id="login-email" />
                <TextInput
                    label="Jelszó"
                    id="login-password"
                    type="password" />
                <Button
                    label="Belépek"
                    onClicked={() => { console.log(`button clicked`) }}
                />
                <div className="">
                    <Link to={`forgot-password`}>
                        <div className="">
                            Elfelejtettem a jelszavam
                        </div>
                    </Link>
                    <Link to={`register`}>
                        <div className="">
                            Regisztrálok
                        </div>
                    </Link>
                </div>
                <pre>{JSON.stringify(nodeMsg, null, 2)}</pre>
            </div>
        </div>
    )
}

export default Login
