import React from 'react'

import './input.scss'


type Props = {
    label?: string
    id?: string
    placeholder?: string
    autoComplete?: string;
    autoFocus?: boolean;
    pattern?: string;
    readOnly?: boolean;
    required?: boolean;
    size?: number;
    value?: string | ReadonlyArray<string> | number;
    width?: number | string;
    type?: string
}

const TextInput: React.FC<Props> = (props) => {

    return (
        <div className="text-input">
            {props.label &&
                <label htmlFor={props.id}>{props.label}</label>
            }
            <input className="form-control" type={props.type ? props.type : 'text'} {...props} />
        </div>
    )
}

export default TextInput
