import React from 'react'
import { useNavigate } from 'react-router-dom'

type Props = {

}

const ForgotPassword: React.FC<Props> = () => {
    const navigate = useNavigate()

    return (
        <>
            <h3>Elfelejtettem a jelszavam</h3>
            <p>Add meg a regisztrált email címed, amelyre küldhetjük a jelszóemlékeztetőt.</p>

            <button onClick={() => navigate(-1)}>Vissza</button>
        </>
    )
}

export default ForgotPassword
