import React from 'react'
import { Route, Routes, Outlet } from 'react-router-dom'

import Login from './login/login.component'
import Register from './register/register.component'
import ForgotPassword from './forgot-password/forgot-password.component'

type Props = {

}

const Auth: React.FC<Props> = () => {


    return (
        <>
            <Outlet />
            <Routes>
                <Route index element={<Login />} />
                <Route path={`register`} element={<Register />} />
                <Route path={`forgot-password`} element={<ForgotPassword />} />
            </Routes>
        </>

    )
}

export default Auth
