import React from 'react'

import CenteredHero from './centered-hero.component'

import HeroNavigation, { LinkContainerDirection } from './hero-navigation.component'

export enum HeroImagePosition {
    Left = 'left',
    Right = 'right',
    Top = 'top',
    Bottom = 'bottom'
}

export enum HeroType {
    Centered,
    LeftRightAligned
}

export type HeroModel = {
    id: string | number
    title: string
    details: string
    heroType: HeroType
    imageUrl?: string
    imagePosition?: HeroImagePosition
    linkPath?: string
    isExternalLink?: boolean
    linkText?: string
    linkContainerDirection?: LinkContainerDirection
}

const Hero: React.FC<HeroModel> = ({
    id,
    title,
    details,
    heroType,
    imageUrl,
    imagePosition,
    linkPath,
    linkText,
    isExternalLink,
    linkContainerDirection
}) => {

    if (heroType === HeroType.Centered) {
        return (
            <CenteredHero
                id={id}
                title={title}
                details={details}
                heroType={heroType}
                imageUrl={imageUrl}
                imagePosition={imagePosition}
                linkPath={linkPath}
                linkText={linkText}
                isExternalLink={isExternalLink}
                linkContainerDirection={linkContainerDirection}
            />
        )
    }

    return (
        <div className="container px-4 py-5">
            <div className={`row align-items-center g-5 py-5 ${imagePosition && imagePosition === HeroImagePosition.Right ? 'flex-lg-row-reverse' : ''}`}>
                <div className="col-10 col-sm-8 col-lg-6">
                    <img src={imageUrl} className="d-block mx-lg-auto img-fluid" alt="Bootstrap Themes" width="700" height="500" loading="lazy" />
                </div>
                <div className="col-lg-6">
                    <h1 className="display-5 fw-bold lh-1 mb-3">{title}</h1>
                    <p className="lead">{details}</p>
                    <HeroNavigation
                        linkPath={linkPath}
                        linkText={linkText}
                        isExternalLink={isExternalLink}
                        linkContainerDirection={linkContainerDirection}
                    />
                </div>
            </div>
        </div>
    )
}

export default Hero