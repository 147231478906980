import React from 'react'

import './footer.scss'

type Props = {

}

const Footer: React.FC<Props> = () => {
    return (
        <footer className="d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top">
            <div className="col-md-4 d-flex align-items-center">

                <span className="text-muted">© 2022 Csinosleszek.hu</span>
            </div>

            <ul className="nav col-md-4 justify-content-end list-unstyled d-flex">
                <li className="ms-3">Twitter</li>
                <li className="ms-3">Instagram</li>
                <li className="ms-3">Facebook</li>
            </ul>
        </footer>
    )
}

export default Footer