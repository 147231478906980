import React from 'react';
import { BrowserRouter } from 'react-router-dom'

import './App.scss';
import Main from './main/main.component'

type Props = {

}

const App: React.FC<Props> = () => {
  return (
    <BrowserRouter>
      <div className="App">
        <Main />
      </div>
    </BrowserRouter>
  );
}

export default App;
