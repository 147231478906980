import React from 'react'

import './button.scss'

type Props = {
    label?: string
    icon?: string
    customClass?: string
    customStyle?: string
    onClicked: () => void
}

const Button: React.FC<Props> = (props) => {

    const btnClass = props.customClass? props.customClass : `btn btn-rounded border-1 border-solid bc-b-600 p-2 bg-b-500 tc-w`

    return (
        <div className={btnClass} onClick={props.onClicked}>
            <span>{props.label}</span>
        </div>
    )
}

export default Button
