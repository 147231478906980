import React from 'react'
import { Link } from 'react-router-dom'

import Navigation from './navigation/navigation.component'
import { RouteList } from '../main-routes'

import './header.scss'

type Props = {

}

const Header: React.FC<Props> = () => {
    return (
        <header className="d-flex flex-wrap align-items-center justify-content-center justify-content-md-between py-3 px-3 mb-4 border-bottom">
            <Link to={'/'}>
                <h1>csinosleszek.hu</h1>
            </Link>
            <Navigation />
            <div className="col-md-3 text-end d-flex justify-content-end">
                <Link to={RouteList.auth.path}>
                    <button type="button" className="btn btn-outline-primary me-2">Login</button>
                </Link>
                <Link to={RouteList.register.path}>
                    <button type="button" className="btn btn-primary">Sign-up</button>
                </Link>
            </div>
        </header>
    )
}

export default Header