import React from 'react'
import { Route, Routes } from 'react-router-dom'

import LandingPage from './landing/landing.component'
import Contact from './contact/contact.component'
import Auth from './auth/auth.compontent'
import Work from './work/work.component'

export const RouteList = {
    main: {
        path: '/'
    },
    work: {
        path: '/work'
    },
    contact: {
        path: '/contact'
    },
    auth: {
        path: '/auth',
    },
    register: {
        path: '/auth/register'
    }
}

type Props = {

}

const MainRoutes: React.FC<Props> = () => {
    return (

        <Routes>
            <Route path={RouteList.main.path} element={<LandingPage />} />
            <Route path={RouteList.work.path} element={<Work />} />
            <Route path={RouteList.contact.path} element={<Contact />} />
            <Route path={`${RouteList.auth.path}/*`} element={<Auth />} />
        </Routes>

    )
}

export default MainRoutes