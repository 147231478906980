import React from 'react'
import { Link } from 'react-router-dom'

import classNames from '../../utils/class-names'

export enum LinkContainerDirection {
    Left,
    Center,
    Right
}

type Props = {
    linkPath?: string
    isExternalLink?: boolean
    linkText?: string
    linkContainerDirection?: LinkContainerDirection
}

const HeroNavigation: React.FC<Props> = ({
    linkPath,
    linkText,
    isExternalLink,
    linkContainerDirection
}) => {
    if (!linkPath) {
        return null
    }

    const containerClass = classNames({
        'd-grid gap-2 d-md-flex': true,
        'justify-content-md-start': linkContainerDirection === LinkContainerDirection.Left,
        'justify-content-sm-center': linkContainerDirection === LinkContainerDirection.Center,
        'justify-content-md-end': linkContainerDirection === LinkContainerDirection.Right,
    });

    return (
        <div className={containerClass}>
            {!isExternalLink ?
                <Link to={linkPath}>
                    <button type="button" className="btn btn-primary btn-lg px-4 me-md-2">{linkText}</button>
                </Link>
                :
                <a href={linkPath} target="_blank" rel="noreferrer">
                    <button type="button" className="btn btn-primary btn-lg px-4 me-md-2">{linkText}</button>
                </a>
            }
        </div>
    )
}

export default HeroNavigation