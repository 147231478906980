import React from 'react'
import { useNavigate } from 'react-router-dom'

type Props = {

}

const Register: React.FC<Props> = () => {
    const navigate = useNavigate()

    return (
        <>
            <h3>Regisztrálok</h3>
            <button onClick={() => navigate(-1)}>Vissza</button>
        </>
    )
}

export default Register
