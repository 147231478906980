import React from 'react'

type Props = {
    title: string
    subtitle?: string
}

const CarouselCaption: React.FC<Props> = ({
    title,
    subtitle
}) => {
    return (
        <div className="carousel-caption">
            <h5>{title}</h5>
            <p>{subtitle ? subtitle : '\u00A0'}</p>
        </div>
    )
}

export default CarouselCaption