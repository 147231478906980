import { MenuItem } from './types/menu.types'
import { RouteList } from '../main/main-routes'

const getMenuItems = () => {
    const menuItems: MenuItem[] = []

    let id: number = 0

    menuItems.push({
        id: id++,
        key: 'work',
        name: 'Munkáink',
        url: RouteList.work.path
    })

    menuItems.push({
        id: id++,
        key: 'contact',
        name: 'Kapcsolat',
        url: RouteList.contact.path
    })

    menuItems.push({
        id: id++,
        key: 'auth',
        name: 'Belépés',
        url: RouteList.auth.path
    })

    return menuItems
}

export default getMenuItems
