import React from 'react'

import Carousel from '../../shared/ui/carousel/carousel.component'
import CarouselItem from '../../shared/ui/carousel/carousel-item.component'
import CarouselCaption from '../../shared/ui/carousel/carousel-caption.component'
import Hero, { HeroImagePosition, HeroModel, HeroType } from '../../shared/ui/heroes/hero.component'
import { RouteList } from '../main-routes'
import { LinkContainerDirection } from '../../shared/ui/heroes/hero-navigation.component'


type Props = {

}

const LandingPage: React.FC<Props> = () => {

    const heroes: HeroModel[] = [
        {
            id: 1,
            title: `Lorem ipsum dolor sit amet`,
            details: `Nullam eu risus luctus, iaculis nibh non, vestibulum massa. Sed volutpat, nisi nec consequat pretium, neque sapien mattis neque, non hendrerit felis justo eu turpis. Etiam pretium magna feugiat lacinia imperdiet. Nullam vitae dictum nisi, quis pulvinar nulla. Ut mollis tincidunt libero sit amet gravida. Praesent dignissim luctus eros. In id urna suscipit, lacinia magna eget, ullamcorper diam. Nullam faucibus consequat consectetur. Pellentesque fringilla felis sem, ut finibus nibh mattis et. Nullam vitae ipsum dolor. Phasellus magna sem, iaculis vitae pretium sed, mollis eget nisi. Aliquam ultricies purus sed maximus tempus. Integer mattis semper risus, in viverra mauris posuere at. Aliquam vitae ante ut diam dictum volutpat.`,
            imageUrl: `https://images.pexels.com/photos/3737594/pexels-photo-3737594.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1`,
            imagePosition: HeroImagePosition.Right,
            linkPath: RouteList.register.path,
            linkText: `Regisztrálok`,
            heroType: HeroType.LeftRightAligned,
            linkContainerDirection: LinkContainerDirection.Right
        },
        {
            id: 2,
            title: `Aenean ac quam ligula`,
            details: `Aenean id dictum neque, vel mattis sem. Nunc finibus fermentum odio vel faucibus. In arcu eros, tempus vel blandit et, pretium sit amet orci.`,
            imageUrl: `https://images.pexels.com/photos/267151/pexels-photo-267151.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1`,
            imagePosition: HeroImagePosition.Bottom,
            linkPath: `https://google.hu`,
            linkText: `Utánanézek`,
            isExternalLink: true,
            heroType: HeroType.Centered,
            linkContainerDirection: LinkContainerDirection.Center
        },
        {
            id: 3,
            title: `Etiam pharetra luctus ex at congue`,
            details: `Praesent quis lorem a libero facilisis imperdiet. Fusce sed rhoncus sem, quis posuere lectus. Pellentesque pellentesque porta augue, et tincidunt purus iaculis eu. Aenean suscipit molestie justo a malesuada. Phasellus faucibus, dui quis facilisis sodales, enim sem efficitur nunc, eget finibus erat tortor et erat.`,
            imageUrl: `https://images.pexels.com/photos/3997989/pexels-photo-3997989.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1`,
            imagePosition: HeroImagePosition.Right,
            heroType: HeroType.LeftRightAligned,
            linkContainerDirection: LinkContainerDirection.Left
        }
    ]

    return (
        <div>
            <Carousel
                autoLoop={false}
                delay={3000}
            >
                <CarouselItem>
                    <img src={'https://www.macmillandictionary.com/external/slideshow/full/152264_full.jpg'} alt="river" />
                    <CarouselCaption
                        title='Folyó'
                        subtitle='Egy folyó naplamentében' />
                </CarouselItem>
                <CarouselItem>
                    <img src={'https://cdn.britannica.com/67/19367-050-885866B4/Valley-Taurus-Mountains-Turkey.jpg'} alt="mountain" />
                    <CarouselCaption
                        title='Hegy'
                        subtitle='Török hegy' />
                </CarouselItem>

                <CarouselItem>
                    <img src={'https://www.revivedwater.eu/sites/default/files/styles/col_/public/2017-08/krystian-tambur-101317.jpg?itok=3_-Ct8kV'} alt="water" />
                    <CarouselCaption
                        title='Víz' />
                </CarouselItem>
            </Carousel>

            {heroes.map(item => (
                <Hero
                    key={item.id}
                    id={item.id}
                    title={item.title}
                    details={item.details}
                    heroType={item.heroType}
                    imageUrl={item.imageUrl}
                    imagePosition={item.imagePosition}
                    linkPath={item.linkPath}
                    linkText={item.linkText}
                    isExternalLink={item.isExternalLink}
                    linkContainerDirection={item.linkContainerDirection}
                />
            ))}

        </div>
    )
}

export default LandingPage