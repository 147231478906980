import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'

import './navigation.scss'
import { MenuItem } from '../../../shared/types/menu.types'
import getMenuItems from '../../../shared/main-menu-items'


type Props = {

}

const Navigation: React.FC<Props> = () => {
    const [menuItems, setMenuItems] = useState<MenuItem[]>([])

    useEffect(() => {
        setMenuItems(getMenuItems())
    }, [])


    return (
        <ul className="nav col-12 col-md-auto mb-2 justify-content-center mb-md-0">
            {menuItems.map(x => (
                <li key={x.key}>
                    <NavLink
                        className={({ isActive }) => isActive ? 'nav-link px-2 link-secondary' : 'nav-link px-2 link-dark'}
                        to={{ pathname: x.url }}>
                        {x.name}
                    </NavLink>
                </li>
            ))}
        </ul>
    )
}

export default Navigation

